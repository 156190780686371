import { useEffect, useState } from "react";

export const useScrollToTop = (scrollLimit) => {
  const [scrolledTop, setScrolledTop] = useState(false);
  const [alreadyScrolled, setAlReadyScolled] = useState(false);
  const [currentScroll, seCurrentScroll] = useState(0);

  const handleScroll = () => {
    seCurrentScroll(window.scrollY);
    setScrolledTop(window.scrollY >= scrollLimit);
    if (window.scrollY >= scrollLimit && alreadyScrolled === false) {
      setAlReadyScolled(true);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    seCurrentScroll(0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    scrolledTop,
    currentScroll,
    alreadyScrolled,
    handleScrollToTop,
    setScrolledTop,
    setAlReadyScolled,
  };
};
