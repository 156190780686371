import React from "react";
import { TestimonyCarouselComponent } from "../../../components/TestimonyCarouselComponent";
import { SwipperComponent } from "../../../components/SwiperComponent";

const data = [
  {
    id: "4",
    isActive: false,
    author: "Frida Karlsson",
    testimony:
      "“I absolutely love how Plnify eliminates the need for my team members to email me about upcoming flights, hotels, or whatever else they may want to know before the itinerary goes out.”",
    position: "Tour Manager: Pitbull, Austin Mahone.",
    image:
      "https://media.licdn.com/dms/image/C4D03AQGxF5Y54BXgOA/profile-displayphoto-shrink_800_800/0/1516767075865?e=2147483647&v=beta&t=mxNw4xnniVC-sUhv0RHlXVeiBHR-kE3gOQOsckmovuI",
  },
  {
    id: "1",
    author: "John Doe",
    testimony:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    position: "CEO, Company XYZ",
    image: "https://robohash.org/1",
    isActive: false,
  },
  {
    id: "2",
    author: "Jane Smith",
    testimony:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    position: "CTO, Tech Solutions",
    image: "https://robohash.org/2",
    isActive: false,
  },
  {
    id: "3",
    author: "Bob Johnson",
    testimony:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    position: "COO, Innovative Corp",
    image: "https://robohash.org/3",
    isActive: false,
  },
];

export const IntegrationSection = ({ toggleModal }) => {
  return (
    <section className="items-start w-full flex-[0_0_auto] inline-flex flex-col relative">
      <div className="flex flex-col justify-center items-center w-full bg-[#F7FAFF] pt-[4%] px-[5%]">
        <div className="items-center px-0 inline-flex flex-col relative">
          <div className="flex flex-col w-full lg:px-[10%] pt-12 lg:py-0 items-center gap-2 lg:gap-6 justify-center">
            <h2 className="section-text self-stretch [font-family:'Montserrat',Helvetica] lg:font-bold text-[#334668] text-center tracking-[0] leading-[normal]">
              Multiple Platforms Integration
            </h2>
            <p className="section-title lg:w-[80%] [font-family:'Montserrat',Helvetica] font-bold text-[#334668] text-center tracking-[0] leading-[normal]">
              Download Plnify on your computer or mobile
            </p>
            <div className="flex items-center justify-center gap-[15px] relative w-full mt-4 lg:m-0">
              <button
                onClick={() => (window.location.href = "/download")}
                className="cursor-pointer btn all-[unset] box-border lg:w-[45%] px-[20px] lg:px-[50px] text-center py-[15px] lg:py-[20px] bg-white lg:bg-[#00AFFF] lg:border-solid lg:border-[#00AFFF] lg:text-white rounded-[30px] lg:rounded-[14px] border-[2.4px] border-solid border-white [font-family:'Poppins',Helvetica] font-semibold text-[#115efb] button-text"
              >
                Download Now
              </button>
              <button
                onClick={() =>
                  (window.location.href = "mailto:info@plnify.com?subject=Schedule%20a%20Plnify%20Demo")
                }
                className="bg-white lg:bg-transparent text-[#115efb] lg:text-[#334668] cursor-pointer btn all-[unset] box-border lg:w-[45%] px-[20px] lg:px-[50px] text-center py-[15px] lg:py-[20px] rounded-[30px] lg:rounded-[14px] border-[2.4px] border-solid lg:border-[#00AFFF] border-white [font-family:'Poppins',Helvetica] font-semibold button-text"
              >
                Schedule Demo
              </button>
            </div>
          </div>
        </div>
        <img
          src="/macbook_device.png"
          alt="devices_image"
          className="shadow-drop relative flex justify-center z-[10] lg:top-[1.5em] my-10 lg:mt-0 w-[100%] lg:w-[50%]"
        />
      </div>
      <div className="flex w-full items-center min-h-[800px] justify-center md:px-[10%] p-[10px] md:py-[50px] relative z-0 bg-[#f9faff]">
        {/* <TestimonyCarouselComponent /> */}
        <SwipperComponent />
      </div>
    </section>
  );
};
