import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useScrollToTop } from "../hooks/useScroll";

export const HeaderComponent = () => {
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState(false);

  const {
    scrolledTop,
    currentScroll,
    alreadyScrolled,
    handleScrollToTop,
    setAlReadyScolled,
    setScrolledTop,
  } = useScrollToTop(200);

  const handleClick = () => {
    setIsActive((prev) => !prev);
  };

  const handleNavigation = () => {
    if (isActive) {
      setIsActive(false);
    }
    handleScrollToTop();
    setAlReadyScolled(false);
    setScrolledTop(false);
  };

  return (
    <header
      className={`w-full flex-wrap h-[70px] lg:h-[80px] justify-center lg:px-[10%] px-[30px] py-[16px] lg:bg-[rgba(255,255,255,0.95)] fixed flex items-center z-[101] ${
        pathname === "/" && !scrolledTop
          ? "bg-transparent"
          : "bg-[rgba(255,255,255,0.95)]"
      }`}
    >
      <div className="flex w-full justify-between items-center h-full">
        <a href="/" className="flex items-center justify-center">
          <img
            className="relative h-[35px] lg:h-[38px]"
            alt="Plnify Logo"
            src="/plnify_logo.png"
          />
        </a>
        <div
          className={` ${
            isActive
              ? "mobile-menu flex shadow-xl p-5 justify-between"
              : "absolute lg:relative opacity-0 top-[-150px] z-[-1] md:z-[unset] lg:top-[unset] lg:flex lg:justify-between lg:items-center lg:opacity-100 w-full h-full"
          } `}
        >
          <div className="lg:h-full lg:flex lg:justify-center w-full h-full items-center lg:p-0">
            <ul className="text-[1rem] h-full justify-center items-center flex flex-col lg:flex-row lg:items-center gap-[40px] lg:gap-[69px] lg:h-full lg:self-center">
              <li className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-[#71848b] tracking-[0] leading-[normal]">
                <NavLink
                  to="/plans"
                  className={({ isActive }) =>
                    isActive ? "link-active " : "visited:text-[#041437]"
                  }
                  onClick={handleNavigation}
                >
                  Pricing
                </NavLink>
              </li>
              <li className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-[#71848b] tracking-[0] leading-[normal]">
                <NavLink
                  onClick={handleNavigation}
                  to="/download"
                  className={({ isActive }) =>
                    isActive ? "link-active" : "visited:text-[#041437]"
                  }
                >
                  Download
                </NavLink>
              </li>
              <li className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-[#71848b] tracking-[0] leading-[normal]">
                <NavLink
                  onClick={handleNavigation}
                  to="/faq"
                  className={({ isActive }) =>
                    isActive ? "link-active" : "visited:text-[#041437]"
                  }
                >
                  FAQ
                </NavLink>
              </li>
              <li className="relative w-fit mt-[-1.00px] [font-family:'Montserrat',Helvetica] font-medium text-[#71848b] tracking-[0] leading-[normal]">
                <NavLink
                  onClick={handleNavigation}
                  to="/privacy-policy"
                  className={({ isActive }) =>
                    isActive ? "link-active" : "visited:text-[#041437]"
                  }
                >
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </div>
          <NavLink
            to="https://web.plnify.com/"
            target="_blank"
            className={(isActive) =>
              `p-0 w-100 lg:inline-flex lg:top-[unset] lg:relative lg:p-[unset] lg:w-[150px] lg:h-[50px] lg:text-[1rem] btn text-center rounded-[14px] custom-btn primary btn cursor-pointer all-[unset] [background:linear-gradient(180deg,rgb(17,94,251)_0%,rgb(44,176,252)_99%,rgb(44,176,252)_100%)] items-center justify-center [font-family:'Poppins',Helvetica] font-semibold text-white text-[1.1rem] w-[88%] ${
                !isActive ? "absolute top-[-100px]" : ""
              }`
            }
          >
            Get started
          </NavLink>
        </div>

        <div
          className={`z-[102] flex lg:hidden menu-san-btn ${
            isActive ? "activated" : "lg:w-0"
          }`}
          onClick={handleClick}
        >
          <div className="mc-bar-1 bg-[rgb(57,198,223)]"></div>
          <div className="mc-bar-2 bg-[rgb(57,198,223)]"></div>
          <div className="mc-bar-3 bg-[rgb(57,198,223)]"></div>
        </div>
      </div>
    </header>
  );
};
