import React, { useEffect, useState } from "react";
import { CollapseComponent } from "./CollapseComponent";
import ContactCard from "./ContactCard";

const collapseData = [
  {
    question: "How to organize your events with Plnify?",
    answer:
      "Plnify allows you to keep your calendar, schedules, travel and team all updated in one simple to use platform!",
  },
  {
    question: "How does Plnify help with info distribution?",
    answer:
      "Here at Plnify - we know the difficulty on updating a large group of people quickly and on the go → Plnify allows for on the go editing of event details that you can then send to your team immediately from your phone as opposed to putting together an email, adding everyones emails, etc.",
  },
  {
    question: "How will Plnify help with my workflow?",
    answer:
      "A event manager, production manager, and assistants normally work separately geographically and also in the way they save and advance event information. Plnify is a centralized workspace where everyone sees the same information and inputs it (for the most part) the same way so the room for information errors is less.",
  },
  {
    question: "How will Plnify help with managing a team?",
    answer:
      "Usually an end-user (band member, dancer, crew member, etc) don’t get their information for the next day until the night before. With Plnify they’ll have access to everything that is available for future events. While eliminating email overload and risk of “losing”, “never getting”, “not finding”, “accidentally deleting” emails. Whatever info they want, whether it’s flights, hotels, or schedule, it’s saved and accessible in an app.",
  },
];
const BannerComponent = () => {
  return (
    <section className="w-full flex flex-col relative items-center mb-36 overflow-hidden">
      <img
        src="/plans_banner.png"
        className="banner-image lg:w-full absolute top-[70px] lg:top-0 z-[-1]"
      />
      <div className="lg:max-w-[800px] lg:min-w-[700px] flex items-center justify-center flex-col gap-6 mt-[50px] lg:mt-[90px] px-10 lg:px-0">
        <h1 className="text-center relative mt-10 [font-family:'Montserrat',Helvetica] font-bold text-white text-[1.5rem] lg:text-[2rem] tracking-[0] leading-[normal]">
          Frequently Asked Questions
        </h1>
        {/* <p className="lg:w-[80%] text-center [font-family:'Montserrat',Helvetica]  text-white text-[1rem] lg:text-[20px] leading-[normal]">
          By installing this application, you agree to Plnify's Terms &
          Conditions and Privacy Policy.
        </p> */}
      </div>

      <div className="my-10 lg:w-[50%] w-[90%]">
        {collapseData.map(({ question, answer }, index) => {
          return (
            <CollapseComponent
              key={question + index}
              question={question}
              answer={answer}
            />
          );
        })}
      </div>

      <div className="flex flex-col items-center justify-center relative w-[100%]">
        <div className="flex flex-col lg:flex-row w-[90%] gap-[20px] lg:gap-[30px] items-center justify-center">
          {/* <ContactCard
            img="/phone-icon.svg"
            title="+1 (646) 786-5060"
            message="We are always happy to help."
          /> */}
          <ContactCard
            img="/contact-icon.svg"
            title="info@plnify.com"
            message="To get a faster answer, please email us."
          />
        </div>

        <div className="questions-container flex flex-col items-center justify-center mt-16">
          <h2>Still have a questions?</h2>
          <p>
            If you cannot find the answer to your question in our FAQ, you can
            always contact us.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BannerComponent;
