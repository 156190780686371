import React from "react";
import BannerComponent from "./components/BannerComponent";
import TableComponent from "../../components/TableComponent";

export const PlansPage = () => {
  return (
    <div className="relative flex flex-col items-center">
      <BannerComponent />
      <div className="flex flex-col lg:flex-row gap-10 lg:p-4 w-[90%] mb-10">
        <TableComponent
          title={"Admin Platform"}
          type={"primary"}
          standard={true}
        />

        <TableComponent title={"Mobile App"} standard={false} />
      </div>
    </div>
  );
};
