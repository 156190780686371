import React from "react";
import { HeaderComponent } from "../components/HeaderComponent";
import { FooterComponent } from "../components/FooterComponent";
import { useLocation } from "react-router-dom";

export const LayoutComponent = ({ children }) => {
  return (
    <>
      <HeaderComponent />
      <main className="">{children}</main>
      <FooterComponent props={children} />
    </>
  );
};
