import React from "react";
import { BannerSection } from "./components/BannerSection";
import { ServicesSection } from "./components/ServicesSection";
import { IntegrationSection } from "./components/IntegrationSection";
import "./style/home-page-style.css";
import { Link } from "react-router-dom";
import { ModalComponent } from "../../components/ModalComponent";

export const Homepage = () => {
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <>
      <ModalComponent
        showModal={showModal}
        toggleModal={toggleModal}
        title="Plnify Download Options"
      >
        
      </ModalComponent>
      <BannerSection />
      <ServicesSection />
      <IntegrationSection toggleModal={toggleModal} />

      {/* <img
        className="left-[1728px] absolute w-[14px] h-[34px] top-[836px] mix-blend-multiply"
        alt="Prev"
        src="https://c.animaapp.com/mTlbE0Ve/img/prev-1.svg"
      />
      <img
        className="left-[990px] absolute w-[14px] h-[34px] top-[836px] mix-blend-multiply"
        alt="Prev"
        src="https://c.animaapp.com/mTlbE0Ve/img/prev.svg"
      /> */}
    </>
  );
};
