import React from "react";
import { Route, Routes } from "react-router-dom";
import { DownloadPage, FaqPage, Homepage, Page404, PlansPage } from "../pages";
import { PrivacyPage } from "../pages/PrivacyPage";
// import { ErrorBoundary } from "../pages/ErrorBoundary";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="faq" element={<FaqPage />} />
      <Route path="plans" element={<PlansPage />} />
      <Route path="download" element={<DownloadPage />} />
      <Route path="privacy-policy" element={<PrivacyPage />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
