import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./router/AppRouter";
import { LayoutComponent } from "./layout/LayoutComponent";

export const App = () => {
  return (
    <BrowserRouter>
      <LayoutComponent>
        <AppRouter />
      </LayoutComponent>
    </BrowserRouter>
  );
};
