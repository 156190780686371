import React from "react";
import BannerComponent from "./components/BannerComponent";

export const DownloadPage = () => {
  return (
    <div className="relative flex flex-col items-center">
      <BannerComponent />
      {/* <img src="/footer-wave.png" className="w-full relative bottom-[-2]" /> */}
    </div>
  );
};
