import React from "react";

export const ModalComponent = ({ toggleModal, showModal, children, title }) => {
  return (
    <div
      onClick={toggleModal}
      className={`font-[Montserrat] modal-container fixed top-0 z-[102] h-screen w-[100%] bg-[rgba(0,0,0,0.7)] flex flex-col items-center justify-center ${
        showModal ? "show-modal-container" : "hidden"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`modal text-center text-2xl font-bold bg-white border rounded-lg relative  ${
          showModal ? "show-modal" : "hide-modal"
        }`}
      >
        <div className="flex justify-between items-center  border-slate-300 border-solid border-b-[1px] w-[100dvw] max-w-[550px] p-2">
          <span className=" font-bold text-[16px] md:text-[1.2rem]">{title}</span>
          <button
            onClick={toggleModal}
            className="btn border-slate-400 border-[1px] w-[30px] h-[30px] cursor-pointer rounded-full bg-transparent text-black"
          >
            X
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};
