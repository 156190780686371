import React from "react";

const ContactCard = ({ img, message, title }) => {
  return (
    <div className="contact-card rounded-xl shadow-lg flex items-center flex-col justify-center bg-white">
      <img className="" src={img} alt="icon" />
      {title.includes("@") ? (
        <a
          href={`mailto:${title}`}
          className="contact-card-title my-4 text-black  font-bold font-[Montserrat] "
        >
          {title}
        </a>
      ) : (
        <h2 className="contact-card-title my-4">{title}</h2>
      )}
      <p className="contact-card-message">{message}</p>
    </div>
  );
};

export default ContactCard;
