import React, { useEffect, useRef, useState } from "react";
import { NoneBorderCard } from "./NoneBorderCard";

const data = [
  {
    id: "4",
    isActive: false,
    author: "Frida Karlsson",
    testimony:
      "“I absolutely love how Plnify eliminates the need for my team members to email me about upcoming flights, hotels, or whatever else they may want to know before the itinerary goes out.”",
    position: "Tour Manager: Pitbull, Austin Mahone.",
    image:
      "https://media.licdn.com/dms/image/C4D03AQGxF5Y54BXgOA/profile-displayphoto-shrink_800_800/0/1516767075865?e=2147483647&v=beta&t=mxNw4xnniVC-sUhv0RHlXVeiBHR-kE3gOQOsckmovuI",
  },
  {
    id: "1",
    author: "John Doe",
    testimony:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    position: "CEO, Company XYZ",
    image: "https://robohash.org/1",
    isActive: false,
  },
  {
    id: "2",
    author: "Jane Smith",
    testimony:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    position: "CTO, Tech Solutions",
    image: "https://robohash.org/2",
    isActive: false,
  },
  {
    id: "3",
    author: "Bob Johnson",
    testimony:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    position: "COO, Innovative Corp",
    image: "https://robohash.org/3",
    isActive: false,
  },
];
export const TestimonyCarouselComponent = () => {
  const [activeTestimony, setActiveTestimony] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);

  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  const handleActiveItem = (item) => {
    setActiveItem((prev) => {
      const newState = prev.map((prevItem) => ({
        ...prevItem,
        isActive: item.id === prevItem.id,
      }));

      return newState;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      let currentIndex = prevIndex === 0 ? data.length - 1 : prevIndex - 1;
      setActiveTestimony(data[currentIndex]);
      return currentIndex;
    });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      let currentIndex = prevIndex === data.length - 1 ? 0 : prevIndex + 1;
      setActiveTestimony(data[currentIndex]);
      return currentIndex;
    });
  };

  const handleCurrentIndex = (index) => {
    setCurrentIndex(index);
    setActiveTestimony(data[index]);
  };

  const handleAutoPlay = () => {
    handleNext();
  };

  useEffect(() => {
    const [testimony, ...rest] = data;
    setActiveTestimony(testimony);
  }, []);

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      handleAutoPlay();
    }, 8000);

    return () => clearInterval(autoplayInterval);
  }, [currentIndex]);

  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    touchEndX.current = event.changedTouches[0].clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchStartX.current - touchEndX.current > 100) {
      handleNext();
    }

    if (touchStartX.current - touchEndX.current < -100) {
      handlePrevious();
    }
  };

  return (
    <>
      <div onClick={() => handlePrevious()} className="cursor-pointer">
        <img
          className="relative self-stretch lg:w-[80px] slider-button hidden lg:block"
          alt="Ar"
          src="https://c.animaapp.com/mTlbE0Ve/img/ar1.svg"
        />
      </div>
      <div className="overflow-hidden flex flex-col justify-between w-[100%] h-[80%] animate__animated animate__fadeIn">
        {activeTestimony && (
          <div
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            draggable={true}
            className="flex flex-col items-center justify-between lg:gap-[20px] lg:px-[10%] lg:py-0"
          >
            {/* <div className="relative w-[270px] h-[270px]">
              <div className="animate__animated animate__pulse relative w-[233px] h-[233px] top-[19px] left-[19px] bg-[url(https://c.animaapp.com/mTlbE0Ve/img/rectangle.svg)] bg-[100%_100%]">
                <div className="overflow-hidden relative w-[180px] h-[180px] top-[26px] left-[26px] bg-[#1d2739] rounded-[89.92px]">
                  <img
                    src={activeTestimony.image}
                    alt=""
                    className="object-fill w-full"
                  />
                </div>
              </div>
            </div> */}
            <div className="animate__animated animate__fadeIn flex flex-col-reverse lg:flex-col items-center gap-[20px] lg:gap-[43px] relative self-stretch w-full h-[50%]">
              <p className="relative self-stretch [font-family:'Poppins',Helvetica] font-normal text-[#4f5a68] lg:text-[25px] text-center tracking-[0] leading-[normal]">
                {activeTestimony.testimony}
              </p>
              <div className="flex flex-col items-start gap-[6px] relative self-stretch w-full flex-[0_0_auto]">
                <h2 className="relative self-stretch [font-family:'Poppins',Helvetica] font-semibold text-[#283646] text-[1.2rem] lg:text-[24px] text-center tracking-[0] leading-[normal]">
                  {activeTestimony.author}
                </h2>
                <p className="relative self-stretch [font-family:'Poppins',Helvetica] font-normal text-black text-[0.9rem] lg:text-[16px] text-center tracking-[0] leading-[normal]">
                  {activeTestimony.position}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="relative flex gap-[10px] cursor-pointer justify-center mt-5 h-[8px]">
          {data.map((item, index) => (
            <div
              key={item.id}
              className={`carousel-slider ${
                currentIndex === index ? "" : "opacity-[20.2%]"
              }`}
              onClick={() => handleCurrentIndex(index)}
            ></div>
          ))}
        </div>
      </div>
      <div onClick={() => handleNext()} className="cursor-pointer">
        <img
          className="relative self-stretch lg:w-[80px] slider-button hidden lg:block"
          alt="Ar"
          src="https://c.animaapp.com/mTlbE0Ve/img/ar2.svg"
        />
      </div>
    </>
  );
};
