import React from "react";
import { CarouselComponent } from "../../../components/CarouselComponent";

const dataStayOrganize = [
  {
    id: 1,
    image: "/calendar_card_img.png",
    title: "Stay Organized",
    description:
      "Schedule and organize your events to keep everyone on the same page",
    buttonType: "no-border",
    isActive: true,
    path: "https://web.plnify.com/",
  },
  // {
  //   id: 2,
  //   image: "/calendar_card_img.png",
  //   title: "Collaborate across devices",
  //   description:
  //     "Simplified workflow with a responsive design built to work across all devices.",
  //   buttonType: "no-border",
  //   isActive: false,
  //   path: "https://web.plnify.com/",
  // },
  {
    id: 2,
    image: "/todo-card.png",
    title: "Organize task efficiently",
    description:
      "Easy to use task management with the ability to assign, track and check off completed task",
    buttonType: "no-border",
    isActive: false,
    path: "https://web.plnify.com/",
  },
];

const dataCollaborate = [
  {
    id: 1,
    image: "/flights_card_img.png",
    title: "Collaborate across devices",
    description: "Simplified workflow with a responsive design built to work across all devices",
    buttonType: "no-border",
    isActive: true,
    path: "https://web.plnify.com/",
  },
];

const dataSimplify = [
  {
    id: 1,
    image: "/team-card.png",
    title: "Simplify Team Management",
    description: "Managing your team in a single place has never been easier",
    buttonType: "no-border",
    isActive: false,
    path: "https://web.plnify.com/",
  },
  {
    id: 2,
    image: "/file-card.png",
    title: "Plnifyles",
    description:
      "Personal secured file management inside your organization, with the ability to intergrate with your other platforms!",
    buttonType: "no-border",
    isActive: true,
    path: "https://web.plnify.com/",
  },
];

export const ServicesSection = () => {
  return (
    <section className="bg-[#031338] w-full items-center gap-[20px] lg:gap-[80px] px-[10%] flex flex-col pb-[6%]">
      <div className="max-w-[1440px] mx-auto">
        <div className="flex flex-col items-start gap-[4px] py-0 w-[85%] self-start">
          <div className="section-text relative w-fit [font-family:'Poppins',Helvetica] font-normal text-white tracking-[0] leading-[normal]">
            Our Services
          </div>
          <p className="section-title w-[100%] lg:w-[500px] relative self-stretch [font-family:'Montserrat',Helvetica] font-bold text-white tracking-[0] leading-[normal]">
            Professional Solution for event planners
          </p>
        </div>
        <div className="overflow-hidden">
          <CarouselComponent
            data={dataStayOrganize}
            imageClass={"relative w-[100%] md:w-[90%] max-w-[700px]"}
          />

          <CarouselComponent
            data={dataCollaborate}
            direction="left"
            imageClass={"relative w-[55%] max-h-[800px]"}
          />

          <CarouselComponent
            imageContainer={""}
            data={dataSimplify}
            imageClass={"relative w-[100%] md:w-[90%] max-w-[700px]"}
          />
        </div>
      </div>
    </section>
  );
};
