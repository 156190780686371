import React, { useEffect, useState } from "react";
import { ModalComponent } from "../../../components/ModalComponent";
import { ItemCard } from "./ItemCard";
import { Link } from "react-router-dom";
import DownloadOptions from "./DownloadOptions";

const BannerComponent = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [showModal]);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <ModalComponent
        showModal={showModal}
        toggleModal={toggleModal}
        title="Download Plnify App"
      >
        <DownloadOptions toggle={toggleModal} />
      </ModalComponent>
      <section className="overflow-hidden pb-10 w-full flex flex-col relative items-center mb-36">
        <img
          src="/plans_banner.png"
          className="banner-image lg:w-full absolute top-[70px] lg:top-0 z-[-1]"
        />
        <div className="lg:max-w-[800px] lg:min-w-[700px] flex items-center justify-center flex-col gap-4 mt-[50px] lg:mt-[90px] px-10 lg:px-0">
          <h1 className="text-center relative mt-24 lg:mt-5 [font-family:'Montserrat',Helvetica] font-bold text-white text-[1.5rem] lg:text-[2rem] tracking-[0] leading-[normal] lg:w-[60%]">
            Download Plnify on your computer or mobile device
          </h1>
          <p className="lg:w-[60%] text-center [font-family:'Montserrat',Helvetica]  text-white text-[1rem] lg:text-[1.1rem] leading-[normal">
            By installing this application, you agree to Plnify's Terms &
            Conditions and Privacy Policy.
          </p>
        </div>

        <div className="download-cards-container mt-10 lg:mt-10 shadow-xl bg-white">
          <ItemCard
            type={"primary"}
            title={"Web Admin App"}
            requirements={["Google Chrome", "Safari", "Firefox", "Opera"]}
            // subtitle={"Web App"}

            img={"/macbook_device.png"}
            btnAction={() => window.open("https://web.plnify.com/", "_blank")}
            showBorder={true}
          />
          <ItemCard
            type={"primary"}
            title={"IOS or Android"}
            subtitle={"Download on"}
            requirements={[
              "iOS 8 or above",
              "Android 6.0 or above",
              "iPhone 4s, iPhone 5, iPhone 5C, iPhone 5S, or above.",
            ]}
            btnAction={toggleModal}
            img={"/iphone.svg"}
          />
        </div>
      </section>
    </>
  );
};

export default BannerComponent;
